import { Form, Formik } from 'formik';
import { graphql, navigate } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'ui-kit/button/button';
import LoadingMessage from 'ui-kit/loading-message/loading-message';
import PhoneNumberText from 'ui-kit/phone-number-text/phone-number-text';
import Text from 'ui-kit/text/text';

import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import BirdiModalContent, { BirdiModalContentAlt } from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import ProfileLayout from 'components/layouts/profile/profile.layout';
import RepresentativeCard from 'components/representative/representative-card.component';

import {
    accountAddRepresentativesRoutine,
    accountGetAllRepresentativesRoutine,
    accountRemoveRepresentativesRoutine
} from 'state/account/account.routines';
import { accountRepresentativesSelector, accountStateSelector } from 'state/account/account.selectors';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';

import { REPRESENTATIVES_SCHEMA } from 'schema/representatives';

import { RepresentativesType } from 'types/representatives';

import './representatives.style.scss';

const isPageDeactivated = true;

const canAddReps = (allRepresentativesData: any, isFetchingRepresentatives: boolean) => {
    const limitedRepresentativesNumber = 10;

    return !(
        (allRepresentativesData && allRepresentativesData.length >= limitedRepresentativesNumber) ||
        isFetchingRepresentatives
    );
};

const AddRepresentativeForm = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const formName = 'addRepresentativesForm';
    const allRepresentativesData = useSelector(accountRepresentativesSelector);
    const { isFetchingRepresentatives } = useSelector(accountStateSelector);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleAddRepresentativeFormCancel = () => {
        dispatch(closeModal({}));
    };

    const showErrorModal = () => {
        dispatch(
            openModal({
                showClose: true,
                type: 'danger',
                size: 'lg',
                headerContent: (
                    <BirdiModalHeaderDanger
                        headerText={t(`pages.profile.representatives.callbacks.error`)}
                        icon="alert"
                    />
                ),
                bodyContent: (
                    <BirdiModalContentAlt subTitle={t(`pages.profile.representatives.callbacks.errorMessage`)} />
                ),
                ctas: [
                    {
                        label: t(`pages.profile.representatives.labels.gotIt`),
                        variant: 'primary',
                        onClick: () => {
                            dispatch(closeModal({}));
                        }
                    }
                ]
            })
        );
    };

    const handleFormSubmit = (values: RepresentativesType) => {
        // If the user has reached the limit of the number of reps they can
        // have, or if rep data still hasn't been retrieved, they should not be
        // able to add another.
        if (!canAddReps(allRepresentativesData, isFetchingRepresentatives)) {
            showErrorModal();
            return;
        }

        const submissionCallbacks = {
            onSuccess: () => {
                setIsSubmitting(false);
                dispatch(
                    openModal({
                        showClose: true,
                        bodyContent: (
                            <BirdiModalContent
                                icon={'success'}
                                title={t(`pages.profile.representatives.callbacks.success`)}
                                body={t(`pages.profile.representatives.callbacks.successMessage`)}
                            />
                        ),
                        ctas: [
                            {
                                label: t(`pages.profile.representatives.labels.gotIt`),
                                variant: 'primary',
                                onClick: handleAddRepresentativeFormCancel
                            }
                        ]
                    })
                );

                // Refresh the list of representatives.
                dispatch(accountGetAllRepresentativesRoutine.trigger());
            },
            onFailure: () => {
                setIsSubmitting(false);
                showErrorModal();
            }
        };

        const representative = {
            patientCareGiverName: values.patientCareGiverName,
            patientPhoneNumber: values.patientPhoneNumber.replace(/[^\d]/g, '').slice(-7),
            patientAreaCode:
                values.patientPhoneNumber[0] === '1'
                    ? values.patientPhoneNumber.replace(/[^\d]/g, '').slice(1, 4)
                    : values.patientPhoneNumber.replace(/[^\d]/g, '').slice(0, 3),
            ...submissionCallbacks
        };

        dispatch(
            accountAddRepresentativesRoutine.trigger({
                ...representative
            })
        );

        // Update the submitting state so that the submit button is disabled.
        setIsSubmitting(true);
    };

    return (
        <Row>
            <Col className="d-flex flex-column">
                <Formik<Partial<RepresentativesType>>
                    onSubmit={(values) => {
                        handleFormSubmit(values);
                    }}
                    validationSchema={REPRESENTATIVES_SCHEMA}
                    initialValues={{
                        patientCareGiverName: '',
                        patientPhoneNumber: ''
                    }}
                >
                    {(formik: any) => (
                        <Form
                            id="representatives-form"
                            onSubmit={formik.handleSubmit}
                            autoComplete="off"
                            className="text-left"
                            data-ga-form-name={formName}
                        >
                            <Row>
                                <Col>
                                    <Text
                                        label={t('pages.profile.representatives.labels.caregiverName')}
                                        name="patientCareGiverName"
                                        onChange={formik.handleChange}
                                        errors={
                                            formik.errors?.patientCareGiverName
                                                ? t('forms.errorMessages.requiredField', {
                                                      label: t('pages.profile.representatives.labels.caregiverName')
                                                  })
                                                : undefined
                                        }
                                        touched={formik.touched.patientCareGiverName}
                                        value={formik.values?.patientCareGiverName}
                                        maxLength={60}
                                        autoComplete="off"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <PhoneNumberText
                                        name="patientPhoneNumber"
                                        label={t('pages.profile.representatives.labels.phoneNumber')}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        errors={
                                            formik.errors?.phoneNumber
                                                ? t('forms.errorMessages.requiredField', {
                                                      label: t('pages.profile.representatives.labels.phoneNumber')
                                                  })
                                                : undefined
                                        }
                                        touched={formik.touched.patientPhoneNumber}
                                        value={formik.values.patientPhoneNumber}
                                        defaultValue={formik.values.patientPhoneNumber}
                                        countryCode={t(`countryCode`)}
                                        autoComplete="off"
                                    />
                                </Col>
                            </Row>

                            <Row className={`flex-column mt-3 text-center`}>
                                <Col className={`text-center`}>
                                    <Button
                                        className="sm-full md-full"
                                        label={t('pages.profile.representatives.labels.submit')}
                                        variant="primary"
                                        type="submit"
                                        disabled={
                                            !formik.values.patientCareGiverName.length ||
                                            !formik.values.patientPhoneNumber.length ||
                                            !formik.dirty ||
                                            isSubmitting
                                        }
                                        onClick={formik.handleSubmit}
                                        dataGAFormName={formName}
                                        dataGALocation="AddRepresentativeForm"
                                    />
                                </Col>
                                <Col className={`text-center mt-3`}>
                                    <Button
                                        label={t('pages.profile.representatives.labels.cancel')}
                                        className="md-full md-pad-y-2"
                                        type="button"
                                        variant="text"
                                        onClick={handleAddRepresentativeFormCancel}
                                        dataGAFormName={formName}
                                        dataGALocation="AddRepresentativeForm"
                                    />
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </Col>
        </Row>
    );
};

const Representatives = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [isMobile, setIsMobile] = useState(false);
    const allRepresentativesData = useSelector(accountRepresentativesSelector);
    const { isFetchingRepresentatives } = useSelector(accountStateSelector);

    useEffect(() => {
        const handleResizeWindow = () => {
            if (window.innerWidth < 700) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        };
        // subscribe to window resize event "onComponentDidMount"
        window.addEventListener('resize', handleResizeWindow);
        handleResizeWindow();
        return () => {
            // unsubscribe "onComponentDestroy"
            window.removeEventListener('resize', handleResizeWindow);
        };
    });

    useEffect(() => {
        // If we already have rep data in the store, do not fetch it again.
        if (!allRepresentativesData) {
            dispatch(accountGetAllRepresentativesRoutine.trigger());
        }
    }, []);

    if (isPageDeactivated) {
        navigate('/secure/profile/family-account');
        return null;
    }

    const addRepresentatives = () => {
        dispatch(
            openModal({
                showClose: true,
                bodyContent: (
                    <BirdiModalContent
                        icon={'none'}
                        title={t(`pages.profile.representatives.addTitle`)}
                        body={<AddRepresentativeForm />}
                    />
                ),
                ctas: []
            })
        );
    };

    const handleDeleteRepresentativesClick = (patientCareGiverSeq: string) => {
        const removeRepresentativesModalContent = (
            <BirdiModalContentAlt subTitle={t('modals.removeRepresentativesModal.body')} />
        );
        dispatch(
            openModal({
                showClose: true,
                type: 'danger',
                size: 'lg',
                headerContent: (
                    <BirdiModalHeaderDanger headerText={t('modals.removeRepresentativesModal.title')} icon="alert" />
                ),
                bodyContent: removeRepresentativesModalContent,
                ctas: [
                    {
                        label: 'Remove Representative',
                        variant: 'primary',
                        onClick: () => {
                            handleRemoveRepresentatives(patientCareGiverSeq);
                            dispatch(closeModal({}));
                            dispatch(accountGetAllRepresentativesRoutine.trigger());
                        },
                        dataGALocation: 'ProfileRemoveRepresentative'
                    },
                    {
                        label: 'Cancel',
                        variant: 'ghost',
                        onClick: () => {
                            dispatch(closeModal({}));
                            dispatch(accountGetAllRepresentativesRoutine.trigger());
                        },
                        dataGALocation: 'ProfileRemoveRepresentative'
                    }
                ]
            })
        );
    };

    const handleRemoveRepresentatives = (patientCareGiverSeq: string) => {
        function removeAndGet() {
            dispatch(accountRemoveRepresentativesRoutine.trigger({ patientCareGiverSeq: patientCareGiverSeq }));
            setTimeout(() => {
                dispatch(accountGetAllRepresentativesRoutine.trigger());
            }, 500);
        }
        if (allRepresentativesData !== undefined) {
            removeAndGet();
        }
    };

    const hasRepresentatives =
        Array.isArray(allRepresentativesData) &&
        allRepresentativesData.length > 0 &&
        Object.keys(allRepresentativesData[0] || {}).length > 0;

    return (
        <ProfileLayout
            eyebrowText={t(`pages.profile.eyebrowText`)}
            title={t(`pages.profile.representatives.title`)}
            heading={t(`pages.profile.representatives.heading`)}
        >
            <Container fluid>
                <Row>
                    <Col>
                        <Button
                            async
                            label={
                                (!isMobile
                                    ? t(`pages.profile.representatives.addDesktop`)
                                    : t(`pages.profile.representatives.addMobile`)) + ' *'
                            }
                            className={!isMobile ? 'no-min-width btn-add-representative' : 'sm-full'}
                            type="button"
                            variant="primary"
                            onClick={addRepresentatives}
                            isBusy={isFetchingRepresentatives}
                            disabled={!canAddReps(allRepresentativesData, isFetchingRepresentatives)}
                        />

                        <LoadingMessage
                            isVisible={isFetchingRepresentatives}
                            text={t(`pages.profile.representatives.loading`)}
                        />
                    </Col>
                </Row>

                {!isFetchingRepresentatives && hasRepresentatives ? (
                    <Row>
                        <Col>
                            {allRepresentativesData.map((rep, idx) => {
                                const phoneNumber =
                                    rep.patientAreaCode +
                                    '-' +
                                    rep.patientPhoneNumber.slice(0, 3) +
                                    '-' +
                                    rep.patientPhoneNumber.slice(-4);

                                return (
                                    <RepresentativeCard
                                        key={`patient-representative-card-${rep.patientCareGiverSeq}`}
                                        name={rep.patientCareGiverName}
                                        phoneNumber={phoneNumber}
                                        openDeleteModal={() =>
                                            handleDeleteRepresentativesClick(rep.patientCareGiverSeq)
                                        }
                                        index={idx}
                                    />
                                );
                            })}
                        </Col>
                    </Row>
                ) : (
                    <Row>
                        <Col>
                            <p className="d-flex justify-content-center text-center mt-4">
                                {t('pages.profile.representatives.noRepresentatives')}
                            </p>

                            {!isMobile && (
                                <div className="d-flex justify-content-center mb-3">
                                    <Button
                                        label={t('pages.profile.representatives.addDesktopBottom')}
                                        variant="text"
                                        onClick={addRepresentatives}
                                        className="text-primary"
                                        type="button"
                                    />
                                </div>
                            )}
                        </Col>
                    </Row>
                )}
            </Container>
        </ProfileLayout>
    );
};

export default Representatives;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
